<template>
  <div class="mailList contentsWidth mx-auto mt-4">
    <div class="contentsWidth mx-auto mt-4">

      <div class="subject">
        <p class="title mb-0">メール送信履歴 - 一覧</p>
        <hr class="title">
      </div>

      <div id="searchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flex-wrap">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                :value="filter.year"
                @input="setFilter('year', $event)"
                :options="yearList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>送信先氏名</div>
            <div>
              <b-form-input
                @input="setFilter('to_name', $event)"
                :value="filter.to_name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchLong inlineBlock searchSpace">
            <div>送信先メールアドレス</div>
            <div>
              <b-form-input
                @input="setFilter('to_mail_address', $event)"
                :value="filter.to_mail_address"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddleLong inlineBlock searchSpace">
            <div>タイトル</div>
            <div>
              <b-form-input
                @input="setFilter('title', $event)"
                :value="filter.title"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>送信者名</div>
            <div>
              <b-form-input
                @input="setFilter('from_user_name', $event)"
                :value="filter.from_user_name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>送信元機能</div>
            <div>
              <b-form-select
                :value="filter.type"
                @input="setFilter('type', $event)"
                :options="typeList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="recordMailTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock mr-2">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>


      <div id="tableWrap" class="mt-2">

        <b-table striped hover
        id='recordMailTable'
        table-class="cmsAppTable"
        thead-class="tableHead"
        tbody-tr-class="dataWrap"
        :items="mailList"
        :fields="header"
        :filter="filter"
        :filter-function="filtering"
        @filtered="onFiltered"
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template v-slot:cell(type)="row">
            {{row.item.typeText}}
          </template>
          <template v-slot:cell(btn)="row">
            <a
              @click="setModalMsg(row.item)"
              class="viewMail bold">
            表示</a>
          </template>
        </b-table>
      </div>
    </div>
    <div class="bottonArea flex flexCenter mt-5">
      <b-button class="btn btn-lg mr-2 bold" to="/cms/top">トップに戻る</b-button>
    </div>

    <b-modal
      ref="mailTextModal"
      size="lg"
      :title="modalMail.title"
      title-class="mailTitleModal"
      :ok-only="true">
      <p class="mb-0">to: {{modalMail.to_mail_address}} ({{modalMail.to_name}})</p>
      <!-- <p class="mb-0">from: {{modalMail.from_user_name}}</p> -->
      <p class="mb-0">送信日: {{modalMail.created_at}}</p>
      <hr/>
      <div class="modalMailBody">
        {{modalMail.text}}
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';

export default {
  name: 'CmsRecoreMailList',
  data() {
    return {
      yearList: [],
      rawMailList: [],
      header: [
        {
          key: 'to_name',
          label: '送信先氏名',
          sortable: true,
        },
        {
          key: 'to_mail_address',
          label: '送信先メールアドレス',
          sortable: true,
        },
        {
          key: 'from_user_name',
          label: '送信者名',
          sortable: true,
        },
        {
          key: 'title',
          label: '件名',
          sortable: true,
        },
        {
          key: 'type',
          label: '機能',
          sortable: true,
        },
        {
          key: 'btn',
          label: '',
          sortable: false,
        },
      ],
      typeList: [
        { value: 1, text: 'ステータス変更' },
        { value: 2, text: 'メール送信' },
        { value: 3, text: 'システム自動送信' },
      ],
      pageOptions: [10, 20, 50],
      isInit: true,
      oldPage: 1,
      modalMail: {
        title: '',
        text: '',
        to_mail_address: '',
        to_name: '',
        from_user_name: '',
        created_at: '',
      },
    };
  },
  methods: {
    filtering(lineData, filter) {
      const toNameFilter = filter.to_name === '' || lineData.to_name.includes(filter.to_name);
      const toMailAddress = filter.to_mail_address === '' || lineData.to_mail_address.includes(filter.to_mail_address);
      const fromUserName = filter.from_user_name === '' || lineData.from_user_name.includes(filter.from_user_name);
      const title = filter.title === '' || lineData.title.includes(filter.title);
      const type = filter.type === null || lineData.type === filter.type;
      return toNameFilter && toMailAddress && fromUserName && title && type;
    },
    async getRecordMailList() {
      this.$store.dispatch('page/onWaiting');
      const params = { year: this.filter.year };
      const response = await api.send('/api/cms/record/mail/list', params)
        .catch((error) => {
          this.errors = error.response.data.message;
          return false;
        });
      if (response !== false) {
        this.hasSend = true;
      }
      this.yearList = response.data.yearList;
      this.rawMailList = response.data.mailList;
      this.$store.dispatch('page/offWaiting');
    },
    onFiltered(filteredItems) {
      this.$store.commit('cmsRecordMailSearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('cmsRecordMailSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('cmsRecordMailSearch/setCurrentPage', 1);
      }
    },
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('cmsRecordMailSearch/setFilter', param);
    },
    setCurrentPage(page) {
      this.$store.commit('cmsRecordMailSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('cmsRecordMailSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('cmsRecordMailSearch/setTotalRows', value);
    },
    setModalMsg(mail) {
      this.$set(this.modalMail, 'title', mail.title);
      this.$set(this.modalMail, 'text', mail.text);
      this.$set(this.modalMail, 'to_mail_address', mail.to_mail_address);
      this.$set(this.modalMail, 'to_name', mail.to_name);
      this.$set(this.modalMail, 'from_user_name', mail.from_user_name);
      this.$set(this.modalMail, 'created_at', moment(mail.created_at).format('YYYY/MM/DD HH:mm:ss'));
      this.$refs.mailTextModal.show();
    },
  },
  computed: {
    filter() {
      return this.$store.state.cmsRecordMailSearch.filter;
    },
    currentPage() {
      return this.$store.state.cmsRecordMailSearch.currentPage;
    },
    perPage() {
      return this.$store.state.cmsRecordMailSearch.perPage;
    },
    totalRows() {
      return this.$store.state.cmsRecordMailSearch.totalRows;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    mailList() {
      return this.rawMailList.map((mail) => {
        let fromUserName;
        if (mail.from_user_id === 0) {
          fromUserName = 'システム';
        } else {
          fromUserName = `${mail.cms_user_info.sei} ${mail.cms_user_info.mei}`;
        }
        const thisType = this.typeList.find((type) => {
          return type.value === mail.type;
        });
        return {
          id: mail.id,
          to_name: mail.to_name,
          to_mail_address: mail.to_mail_address,
          title: mail.title,
          text: mail.text,
          from_user_name: fromUserName,
          type: mail.type,
          typeText: thisType.text,
          created_at: mail.created_at,
        };
      });
    },
  },
  watch: {
    'filter.year': function () {
      this.getRecordMailList();
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.oldPage = this.currentPage;
    this.setFilter('year', moment().format('YYYY'));
    await this.getRecordMailList();
    this.isInit = false;
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  #recordMailTable thead th:nth-of-type(1) {
    width: 140px !important;
  }
  #recordMailTable thead th:nth-of-type(2) {
    width: 250px !important;
  }
  #recordMailTable tbody td:nth-of-type(2) {
    width: 250px !important;
    max-width: 250px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #recordMailTable thead th:nth-of-type(3) {
    width: 140px !important;
  }
  #recordMailTable thead th:nth-of-type(4) {
    width: 350px !important;
    max-width: 350px !important;
  }
  #recordMailTable tbody td:nth-of-type(4) {
    width: 350px !important;
    max-width: 350px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #recordMailTable thead th:nth-of-type(5) {
    width: 150px !important;
  }
</style>

<style scoped>
  #searchWrap input, #searchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchLong {
    width: 340px;
  }

  .searchMiddleLong {
    width: 270px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
    margin-bottom: 5px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }

  .viewMail:hover {
    cursor: pointer;
  }

  .modalMailBody {
    white-space: pre-line;
  }

</style>
